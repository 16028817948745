import React from 'react';
import LoginPage from './pages/LoginPage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AgricultureEngine from "./pages/HomePage";
import AboutUs from './pages/AboutUs';

function App() {
  return (
    <Router basename="/agriquery">
      <Routes>
        <Route path="/" element={<AgricultureEngine />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;