import React, { useState } from "react";
import TopNavBar from "../components/TopNavBar";
import HomePageCarousel from "../components/HomepageCarousel";
import CopyRightsFooter from "../components/CopyRightsFooter";

const TestimonialCard = ({ image, name, text, rating }) => {
	return (
	  <div className="rounded-lg p-4 sm:p-6 max-w-sm mx-auto">
		<img
		  src={image}
		  alt={name}
		  className="w-20 h-20 sm:w-24 sm:h-24 rounded-full mx-auto mb-4 object-cover"
		  style={{ border: "5px solid #018501", background: "#EEF0F3" }}
		/>
		<h3 className="text-lg sm:text-xl font-semibold text-center mb-2 text-[#646464]">
		  {name}
		</h3>
		<p className="text-sm sm:text-base text-[#5E5E5E] text-center mb-4">{text}</p>
		<div className="flex justify-center gap-1">
		  {[...Array(5)].map((_, i) => (
			<span
			  key={i}
			  className={`text-xl sm:text-2xl ${
				i < rating ? "text-[#018501]" : "text-gray-300"
			  }`}
			>
			  ★
			</span>
		  ))}
		</div>
	  </div>
	);
  };

const GalleryCard = ({ image, text }) => {
	return (
	  <div className="relative rounded-lg overflow-hidden h-[300px] sm:h-[350px]">
		<img src={image} alt={text} className="w-full h-full object-cover" />
		<div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-3 sm:p-4">
		  <p className="text-xs sm:text-sm text-center text-white">{text}</p>
		</div>
	  </div>
	);
};

const LandingPage = () => {
const [inputText, setInputText] = useState("");
const [loading, setLoading] = useState(false);
const [response, setResponse] = useState("");
const [messages, setMessages] = useState([]);

const reviews = [
	{
		name: "Basavaraju",
		image: `${process.env.REACT_APP_IMAGE_PATH}/images/basavaraj.png`,
		text: "To my knowledge, here is the information that is different from mine. If it comes to the benefit of the journalists, writers and enthusiastic farmers, it will be good!",
		rating: 4,
	}
	// Add more reviews as needed
];

const galleryItems = [
	{
	image: `${process.env.REACT_APP_IMAGE_PATH}/images/advt.jpeg`,
	text: "ADVT here",
	},
	// Add more gallery items as needed
];

const handleSubmit = async () => {
	if (!inputText) {
	console.log("No input provided");
	return;
	}
	const currentQuestion = inputText;
	setLoading(true);

	try {
	const response = await fetch(
		"https://api.braininc.net/stream/bas-demo-v4/nlp/completions_generation",
		{
		method: "POST",
		headers: {
			"brain-guest-user-key": "071ae1a6-4b88-4386-a481-94cae8374d53",
			"x-brain-imagica-id": "92794e98-8d3f-4f51-888a-0e65fba9335f",
			"content-type": "application/json",
		},
		body: JSON.stringify({
			identifier_type: "object_id",
			identifier_value: "5338917",
			variables: {
			input: currentQuestion,
			Question: currentQuestion,
			},
		}),
		}
	);

	if (!response.ok) {
		throw new Error("Network response was not ok");
	}

	const data = await response.json();
	const newMessage = {
		question: currentQuestion,
		answer: data.choices[0]["message"]["content"],
	};
	setMessages((prev) => [...prev, newMessage]);
	setInputText(""); // Clear input after submission
	} catch (error) {
	console.error("Answer fetch error:", error);
	} finally {
	setLoading(false);
	}
};

const imagePath = process.env.REACT_APP_IMAGE_PATH;

return (
	<div>
	<div className="relative bg-[#EEF0F3]">
		<div
		className={`inset-0 ${
			messages.length > 0 ? "bg-cover bg-no-repeat fixed" : "absolute bg-cover bg-center"
		  }`}
		style={{
			backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}/images/home-background.png)`,
			// backgroundPositionY: "60%",
		}}
		  
		/>
		<div className="absolute" />
		<TopNavBar />

		<main className="relative pt-32 pb-10 px-4 sm:px-6 lg:px-8 h-auto">
			<div className="max-w-6xl mx-auto">
				{/* Hero Section */}
				<div className="text-center mb-8 sm:mb-16">
				<h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-[#5E5E5E] px-2">
					{messages.length > 0 ? (
					"KNN - AgriQuery"
					) : (
					<>
						First Ever AI-Powered
						<br className="sm:hidden" />
						<span className="hidden sm:inline"> </span>
						Agriculture Engine
					</>
					)}
				</h1>

				{messages.length > 0 && (
					<div className="max-w-2xl mx-auto px-2 sm:px-4">
					{messages.map((msg, index) => (
						<div key={index} className="mb-6 sm:mb-8">
						<div className="relative">
							<img
							alt="search"
							className="h-[20px] px-4 sm:px-8 absolute top-4"
							src={`${process.env.REACT_APP_IMAGE_PATH}/icons/search.svg`}
							/>
							<input
							placeholder={msg.question}
							className="w-full px-12 sm:px-16 py-3 sm:py-4 rounded-lg !important text-sm sm:text-base"
							type="text"
							value=""
							/>
							<button
							className="mt-3 sm:mt-4 text-white px-3 sm:px-4 py-2 rounded-lg w-32 sm:w-40 opacity-50 text-sm sm:text-base"
							style={{
								background:
								"linear-gradient(90deg, #00A400 -14.78%, #003E00 113.14%)",
							}}
							disabled={true}
							>
							Submit
							</button>
						</div>
						<div className="mt-4 p-3 sm:p-4 bg-white rounded-lg">
							<p 
								className="text-left text-[#323030] text-sm sm:text-base"
								style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
							>
							{msg.answer}
							</p>
						</div>
						<p className="text-sm sm:text-md text-left text-[#4e4c4c] mt-2">
							This is AI Generated Content, May be inaccurate*
						</p>
						</div>
					))}

					<div className="my-4 relative">
						<img
						src={`${process.env.REACT_APP_IMAGE_PATH}/icons/search.svg`}
						alt="search"
						className="h-[20px] px-4 sm:px-8 absolute top-4"
						/>
						<input
						type="text"
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
						onKeyUp={async (e) => {
							if (e.key === 'Enter' && !loading && inputText.trim()) {
							e.preventDefault();
							await handleSubmit();
							}
						}}
						placeholder="Enter your Agriculture related question..."
						className="w-full px-12 sm:px-16 py-3 sm:py-4 rounded-lg !important text-sm sm:text-base"
						/>
						<button
						className="mt-3 sm:mt-4 text-white px-3 sm:px-4 py-2 rounded-lg w-32 sm:w-40 text-sm sm:text-base"
						style={{
							background:
							"linear-gradient(90deg, #00A400 -14.78%, #003E00 113.14%)",
						}}
						onClick={handleSubmit}
						disabled={loading}
						>
						{loading ? "Loading..." : "Submit"}
						</button>
					</div>
					</div>
				)}

				{messages.length === 0 && (
					<>
					<p className="text-base sm:text-lg mb-6 sm:mb-8 max-w-xl mx-auto text-[#5E5E5E] px-4">
						KNN- AgriQuery offers personalized insights on soil, seeds,
						crops management and more. Tailored advice for every farmers,
						researchers from novice to seasoned. Let's grow smarter,
						together!
					</p>
					<div className="max-w-2xl mx-auto relative px-2 sm:px-4">
						<img
						src={`${imagePath}/icons/search.svg`}
						alt="search"
						className="h-[20px] px-4 sm:px-8 absolute top-4"
						/>
						<input
						type="text"
						value={inputText}
						onChange={(e) => setInputText(e.target.value)}
						onKeyUp={async (e) => {
							if (e.key === 'Enter' && !loading && inputText.trim()) {
							e.preventDefault();
							await handleSubmit();
							}
						}}
						placeholder="Enter your Agriculture related question..."
						className="w-full px-12 sm:px-16 py-3 sm:py-4 rounded-lg !important text-sm sm:text-base"
						/>
						<button
						className="mt-3 sm:mt-4 text-white px-3 sm:px-4 py-2 rounded-lg w-32 sm:w-40 text-sm sm:text-base"
						style={{
							background: "linear-gradient(90deg, #00A400 -14.78%, #003E00 113.14%)",
						}}
						onClick={handleSubmit}
						disabled={loading}
						>
						{loading ? "Loading..." : "Submit"}
						</button>
					</div>
					</>
				)}
				</div>
			</div>
			</main>
	</div>
	{messages.length === 0 && (
		<div className="relative max-w-6xl mx-auto pt-4 pb-10 px-4 sm:px-6">
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8 mb-8 sm:mb-16">
			{/* Testimonial Carousel */}
				<div className="h-[350px] sm:h-[400px] md:h-[350px]">
					<HomePageCarousel
					items={reviews}
					Component={TestimonialCard}
					className="p-4 sm:p-6 rounded-lg"
					style={{ "box-shadow": "1px 4px 4px 0px #0000001A" }}
					/>
				</div>

				{/* Gallery Carousel */}
				<div className="h-[350px] sm:h-[400px] md:h-[350px]">
					<HomePageCarousel
					items={galleryItems}
					Component={GalleryCard}
					className="p-4 sm:p-6 rounded-lg"
					style={{ background: "#00000099" }}
					/>
				</div>

				{/* Info Card */}
				<div
					className="p-4 sm:p-6 rounded-lg h-[350px] sm:h-[400px] md:h-[350px]"
					style={{
					"box-shadow": "1px 4px 4px 0px rgba(0, 0, 0, 0.1)",
					background: "#EEF0F3",
					}}
				>
					<div className="text-center h-full flex flex-col justify-between">
					<img
						src={`${process.env.REACT_APP_IMAGE_PATH}/images/krishi-logo.png`}
						alt="Krushi Samaya"
						className="h-10 sm:h-12 mx-auto mb-4"
					/>
					<p className="text-sm sm:text-base text-[#5E5E5E] flex-grow">
						KNNKrushisamaya is a comprehensive Kannada website dedicated
						to agriculture, farmers' movement, and related subjects. Our
						goal is to provide farmers, agricultural enthusiasts, and
						everyone else with reliable & up-to-date information on
						various aspects of agriculture.
					</p>
					
						<a href="https://knnkrushisamaya.com/"
						className="w-full text-[#018501] mt-4 text-right inline-block"
					>
						View
					</a>
					</div>
				</div>
			</div>
		</div>
	)}
	<CopyRightsFooter />
	</div>
);
};

export default LandingPage;
