import React, { useState } from "react";
import TopNavBar from '../components/TopNavBar';
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
	const navigate = useNavigate();
const [contactNumber, setContactNumber] = useState("");

const handleLogin = () => {
    navigate('/home');
  };

return (
	<div className="flex flex-col space-y-4 w-80 max-w-sm">
	<input
		type="tel"
		value={contactNumber}
		onChange={(e) => setContactNumber(e.target.value)}
		placeholder="Contact number"
		className="w-full px-4 py-3 rounded-lg bg-white border border-gray-300 focus:border-green-500 focus:ring-2 focus:ring-green-200 outline-none"
	/>
	<button className="w-full bg-[#008000] text-white py-3 rounded-lg hover:bg-green-700 transition-colors" onClick={handleLogin}>
		Login
	</button>
	<div className="flex items-center justify-center space-x-4">
		<div className="flex-1 h-px bg-gray-300"></div>
		<span className="text-gray-500">Or</span>
		<div className="flex-1 h-px bg-gray-300"></div>
	</div>
	<button className="w-full bg-yellow-400 text-black py-3 rounded-lg hover:bg-yellow-500 transition-colors">
		Create new account
	</button>
	</div>
);
};

const LoginPage = () => {
return (
	<div className="min-h-screen relative">
	<div
		className="absolute inset-0 bg-cover bg-center"
		style={{
		backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}/images/page-background.jpeg)`,
		"background-position-y": "80%",
		}}
	/>
	<div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-30" />
	<TopNavBar />
	<div className="relative min-h-screen flex items-center justify-center px-4">
		<div className="bg-[#00000099] bg-opacity-80 p-8 rounded-xl backdrop-blur-sm shadow-xl">
		<h2 className="text-4xl font-bold text-white mb-8 text-center">
			Login
		</h2>
		<LoginForm />
		</div>
	</div>
	</div>
);
};

export default LoginPage;
