import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const HomePageCarousel = ({ items, Component, className, style }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const next = () => {
        setCurrentIndex((prev) => (prev + 1) % items.length);
    };
    
    const prev = () => {
        setCurrentIndex((prev) => (prev - 1 + items.length) % items.length);
    };
    
    return (
        <div className={`relative bg-[#EEF0F3] rounded-lg`} style={style}>
        <Component {...items[currentIndex]} />
        {/* <button onClick={prev} className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hidden" > <ChevronLeft className="w-6 h-6" /> </button> */}
        {/* <button onClick={next} className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-lg hidden" > <ChevronRight className="w-6 h-6" /> </button> */}
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-2 ">
        {items.length > 1 && 
            items.map((_, idx) => (
            <button
                key={idx}
                className={`w-2 h-2 rounded-full ${
                idx === currentIndex ? "bg-[#018501]" : "bg-gray-300"
                }`}
                onClick={() => setCurrentIndex(idx)}
            />
            ))}
        </div>
        </div>
    );
    };

export default HomePageCarousel;
