import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white shadow-md z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <a href="/agriquery/">
            <div className="flex-shrink-0">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}/images/krishi-logo.png`}
                alt="Logo"
                className="h-8 w-auto"
              />
            </div>
          </a>

          {/* Hamburger Menu */}
          <div className="flex items-center md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-gray-900 bg-white"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4 md:space-x-16">
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Packages
            </a>
            <a href="/agriquery/about-us" className="text-gray-600 hover:text-gray-900">
              About Us
            </a>
            <a href="#" className="text-gray-600 hover:text-gray-900">
              Account
            </a>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden ${isOpen ? "block" : "hidden"} bg-white shadow-md`}
        id="mobile-menu"
      >
        <div className="px-4 pb-4 space-y-2">
          <a href="#" className="block text-gray-600 hover:text-gray-900">
            Packages
          </a>
          <a href="/agriquery/about-us" className="block text-gray-600 hover:text-gray-900">
            About Us
          </a>
          <a href="#" className="block text-gray-600 hover:text-gray-900">
            Account
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
