import React from "react";

const CopyRightsFooter = () => {
	const currentYear = new Date().getFullYear();

	return (
		<div className="relative max-w-full mx-auto pb-10 px-4 sm:px-10 lg:px-20 bottom-0 left-0 right-0">
		<div className="bg-opacity-90 text-center sm:text-right pr-0 sm:pr-4 text-sm sm:text-base">
			<span>Copyright © {currentYear} Chiranthana Media Solutions</span>
			<span
			className="mx-2 sm:mx-4"
			style={{
				color: "#008000",
				fontWeight: "900",
			}}
			>
			|
			</span>
			<span>Powered by Imagica</span>
			<span
			className="mx-2 sm:mx-4"
			style={{
				color: "#008000",
				fontWeight: "900",
			}}
			>
			|
			</span>
			<span>Driven by AIM</span>
		</div>
		</div>
	);
};

export default CopyRightsFooter;
