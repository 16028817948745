import React from "react";
import TopNavBar from "../components/TopNavBar";
import CopyRightsFooter from "../components/CopyRightsFooter";

const AboutUs = () => {
	return (
		<>
		<div className="min-h-full relative">
			{/* Background Image */}
			<div
			className="absolute inset-0 bg-cover bg-center"
			style={{
				backgroundImage: `url(${process.env.REACT_APP_IMAGE_PATH}/images/page-background.jpeg)`,
				backgroundPositionY: "80%",
			}}
			/>
			<div className="absolute inset-0" />

			{/* Top Navigation Bar */}
			<TopNavBar />

			{/* Main Content */}
			<div className="relative flex items-center justify-center pt-20 px-4 sm:px-10 lg:px-20 py-10">
			<div className="bg-[#00000099] opacity-90 py-8 px-6 sm:px-10 lg:px-20 rounded-xl shadow-xl max-w-full sm:max-w-3xl lg:max-w-6xl w-full">
				<h2 className="text-3xl sm:text-4xl font-bold text-white mb-6 text-center">
				About Us
				</h2>
				<p className="text-white text-base sm:text-lg lg:text-xl text-center whitespace-pre-line">
					At KNN-AgriQuery, we strive to be your go-to info and  resource for agriculture. Our AI-powered platform is designed to provide expert answers and resources for all your agriculture-related queries. Whether you're a seasoned farmer, a curious enthusiast or someone looking to start a journey in the field of agriculture, we are here to help.<br /><br />

					With a strong team of agricultural experts and cutting-edge technology, we provide accurate and detailed responses to your curious questions; from soil-seed-nutrients-pests & diseases, crop management, crop cultivation techniques,  livestock management, soil health and more. We cover a wide range of topics to cater to your diverse needs.<br /><br />

					Our platform combines the power of artificial intelligence with extensive agricultural knowledge to bring you the most relevant and up-to-date information. We understand the importance of staying informed in the ever-evolving world of agriculture and we are committed to keep you abreast of the latest trends, techniques and research.<br /><br />

					In addition to our expert answers, we also provide carefully curated resource links to further enhance your understanding and assist you in your agricultural endeavors. These resources include research papers, articles, videos and websites that provide in-depth information on various agricultural topics.<br /><br />

					At KNN-AgriQuery, we value your trust and strive to provide you with accurate and reliable information. Our goal is to empower you with the knowledge and resources you need to succeed in the field of agriculture. Whether you have a simple question or a complex query, let our AI-powered platform be your guide.<br /><br />

					Thank you for choosing KNN-AgriQuery. We look forward to facilitate your agricultural journey.
				</p>
			</div>
			</div>

			{/* Footer */}
			<CopyRightsFooter />
		</div>
		</>
	);
};

export default AboutUs;